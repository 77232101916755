<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Template Edit</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Type</p>
                        <el-form-item prop="type">
                            <el-select v-model="Pages.type" class="w-full">
                                <el-option v-for="(item,i) in TemplateType" :key="i" :label="item.label" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="title_ua">
                            <el-input v-model="Pages.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="description_ua">
                            <RichText  v-model="Pages.description_ua" />

                            <!-- <editor api-key="u8t6jmmtaps4zh31avvgfv9xl9x0x3dt97ndu59rfo413tm7" :init="options" class="tinymce" v-model="Pages.description_ua" /> -->
                        </el-form-item>
                    </div>
                    <div class="item" v-if="Pages.type==2">
                        <el-form-item prop="image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Upload Image
                                    <input type="file" name="image" @change="UploadImage" accept="image/png, image/gif, image/jpeg">
                                </button>
                                <p :class="erros.image?'c-red':''"> {{Pages.image.name? Pages.image.name:Pages.image}} </p>
                            </div>
                            <p v-if="erros.image" class="error-text">Upload Image</p>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                    <div class="item">
                        <p> Title</p>
                        <el-form-item prop="title_en">
                            <el-input v-model="Pages.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="description_en">
                            <RichText  v-model="Pages.description_en" />
                        </el-form-item>
                    </div>

                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push(`/admin/pages/${$route.params.id}/templates`)"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="Post()"> Edit</button>
                </div>
            </el-form>
        </div>  
    </div>
</div>
</template>

<script>  
import RichText from '../../../components/RichText/index.vue'
export default {
    components: { 
        RichText
    },
    data() {
        return { 
            langName: 'ua',
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                page_id: this.$route.params.id,
                title_ua: '',
                title_en: '',
                description_ua: '',
                description_en: '',
                type: '',
                image: ''

            },
            PagesRules: {
                title_ua: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],
                title_en: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],
                description_ua: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],
                description_en: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],
                type: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],
                image: [{
                    required: true,
                    message: 'Будь ласка, введіть інформацію',
                    trigger: 'blur'
                }],

            },
        }

    },
    computed: {
        TemplateType() {
            return this.$store.state.TemplateType
        }
    },
    methods: {
        UploadImage(event) {
            this.Pages.image = event.target.files[0];

        },

        Post() {
            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true

                    let data = new FormData();
                    for (let i of Object.keys(this.Pages)) {
                        if (i != 'image') {
                            data.append(i, this.Pages[i]);
                        } else {
                            if (this.Pages[i].name) {
                                data.append(i, this.Pages[i]);
                            }
                        }

                    }

                    this.axios.post(`/admin/blog/${this.$route.params.template_id}`, data).then(res => {
                            console.log(res)
                            this.$router.push(`/admin/pages/${this.$route.params.id}/templates`)
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    if (this.Pages.title_ua && this.Pages.description_ua && this.Pages.image && this.Pages.type) {
                        this.langName = 'en'
                    }
                    if (this.Pages.title_en && this.Pages.description_en) {
                        this.langName = 'ua'
                    }
                }

            })

        },
        Get() {
            this.loading = true
            this.axios.get(`/admin/blog/${this.$route.params.template_id}`)
                .then(res => {
                    for (let i of Object.keys(this.Pages)) {
                        this.Pages[i] = res.data.data[i] ? res.data.data[i] : ''
                    }
                    this.Pages._method = 'PUT'
                })
                .catch(error => {
                    let ERRORS = error.response.data.errors;
                    let keys = Object.keys(ERRORS);
                    let error_msg = ERRORS[keys[0]];
                    this.$notify.error({
                        title: 'Произошла ошибка',
                        message: error_msg ? error_msg : "Произошла ошибка",
                    });

                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.Get()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
